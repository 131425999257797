<template>
  <div class="PERInformation" style="background-color: #fff">
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
    </div>
    <!-- 头部tabs -->
    <el-tabs v-model="activeName" @tab-click="handleClicktab">
      <el-tab-pane
        v-for="(item, index) in tabslist"
        :key="index"
        :label="item"
        :value="index"
      ></el-tab-pane>
    </el-tabs>
    <el-form :inline="true" :model="Searchlist" class="demo-form-inline">
      <el-form-item label="商家名称">
        <el-input v-model="Searchlist.value1"></el-input>
      </el-form-item>
      <el-form-item label="负责人电话">
        <el-input v-model="Searchlist.value2"></el-input>
      </el-form-item>
      <el-form-item
              :required="true"
              label="商户类型"
            >
              <el-select
                v-model="Mectype"
                placeholder="请选择商户类型"
              >
                <el-option
                  label="商户"
                  value="商户"
                ></el-option>
                <el-option
                  label="机构商户"
                  value="机构商户"
                ></el-option>
              </el-select>
            </el-form-item>
      <label> 状态 </label>
        <el-select v-model="mecstatus" placeholder="请选择" clearable>
          <el-option
            label="待审核"
            value="1"
          >
          </el-option>
          <el-option
            label="已通过"
            value="2"
          >
          </el-option>
          <el-option
            label="已拒绝"
            value="3"
          >
          </el-option>
        </el-select>
      <el-form-item>
        <el-button @click="Searchclick" type="primary">查询</el-button>
      </el-form-item>
    </el-form>
    <el-button
      type="warning"
      @click="
        activeName == 0
          ? (dialogVisible1 = !dialogVisible1)
          : (dialogVisible = !dialogVisible)
      "
      class="blt"
      plain
      >添加商户</el-button
    >
    <div v-if="activeName == 0">
      <el-table :data="tableData">
        <el-table-column label="商家名称" prop="name"></el-table-column>
        <el-table-column prop="name" label="负责人姓名"></el-table-column>
        <el-table-column prop="name" label="负责人电话"> </el-table-column>
        <el-table-column prop="name" label="录入时间"> </el-table-column>
        <el-table-column prop="name" label="区域"> </el-table-column>
        <el-table-column prop="name" label="地址"> </el-table-column>
        <el-table-column prop="name" label="备注"> </el-table-column>
      </el-table>
    </div>
    <!-- 新增商户点击窗口 未入住-->
    <el-dialog :visible.sync="dialogVisible1" width="700px" title="新增商户">
      <ul style="padding-left: 30px; padding-right: 30px">
        <li style="display: flex">
          <p style="flex: 1; text-align: right">
            <span style="color: #f56c6c">*</span>商家名称
            <el-input v-model="Addmerchantlist.name"></el-input>
          </p>
          <p style="flex: 1; text-align: right">
            <span style="color: #f56c6c">*</span>负责人姓名
            <el-input v-model="Addmerchantlist.personincharge"></el-input>
          </p>
        </li>
        <li style="display: flex; margin: 21px 0">
          <p style="flex: 1; text-align: right">
            <span style="color: #f56c6c">*</span>负责人电话
            <el-input v-model="Addmerchantlist.Telephone"></el-input>
          </p>
          <p style="flex: 1; text-align: right">
            <span style="color: #f56c6c">*</span>备注
            <el-input v-model="Addmerchantlist.remarks"></el-input>
          </p>
        </li>
        <li style="display: flex; margin: 21px 0">
          <p style="width: 50%; text-align: right">
            <span style="color: #f56c6c">*</span>地址
            <input
              v-model="Addmerchantlist.address"
              type="text"
              class="input-1"
            />
          </p>
          <p style="margin-left: 20px">
            <el-button @click="dialogmap = !dialogmap">选择位置</el-button>
          </p>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible1 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 没修改之前的 -->
    <div v-if="activeName == 1">
      <el-dialog
        class="dialog"
        title="添加商户"
        :visible.sync="dialogVisible"
        :center="true"
        width="1045px"
      >
        <el-form
          id="dialogfrom"
          ref="form"
          class="dialogfrom"
          :model="form"
          label-width="110px"
        >
          <div>
            <el-form-item :required="true" label="商户名称">
              <el-input v-model="form.mechanism_name"></el-input>
            </el-form-item>
            <el-form-item
              :required="true"
              label="商户类型"
            >
              <el-select
                v-model="Mectype"
                placeholder="请选择商户类型"
              >
                <el-option
                  label="商户"
                  value="商户"
                ></el-option>
                <el-option
                  label="机构商户"
                  value="机构商户"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :required="true" label="选择位置">
              <el-button
                @click="dialogmap = !dialogmap"
                style="
                  margin-top: 30px;
                  width: 220px;
                  color: #c0c4cc;
                  z-index: 100;
                "
                >选择位置</el-button
              >
            </el-form-item>
            <el-form-item :required="true" label="商户地址">
              <el-input v-model="form.mechanism_addr"></el-input>
            </el-form-item>
            <el-form-item label="商户经度">
              <el-input v-model="form.longitude"></el-input>
            </el-form-item>
            <el-form-item label="商户纬度">
              <el-input v-model="form.latitude"></el-input>
            </el-form-item>
            <el-form-item label="机构电话">
              <el-input v-model="form.mechanism_telephone"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="负责人职位">
              <el-input v-model="form.contacts"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="负责人姓名">
              <el-input v-model="form.contacts_title"></el-input>
            </el-form-item>
            <el-form-item :required="true" label="负责人电话">
              <el-input v-model="form.contact_telephone"></el-input>
            </el-form-item>
            <el-form-item label="收款账号">
              <el-input v-model="form.payee_logon_id"></el-input>
            </el-form-item>
            <el-form-item label="绑定用户">
              <el-select
                v-model="selected_user"
                placeholder="请选择"
                value-key="user_id"
                filterable
                style="margin-top: 10px"
                @change="changeuser"
                remote
                :remote-method="getUser"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.user_id"
                  :label="item.nick_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商户简介">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="form.introduction_content"
              >
              </el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="核销限制">
              <el-input v-model="form.restrictions_count"></el-input>
            </el-form-item>
            <el-form-item
              :required="true"
              :hide-required-asterisk="true"
              label="商户类别"
            >
              <el-select
                v-model="region"
                value-key="id"
                multiple
                placeholder="请选择商户类别"
              >
                <el-option
                  v-for="(item, index) in ListPageChild"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :required="true"
              :hide-required-asterisk="true"
              label="商户子类别"
            >
              <el-select
                multiple
                v-model="form.categories_child"
                placeholder="请选择商户子类别"
              >
                <el-option
                  :label="item.name"
                  :value="item.name"
                  v-for="(item, index) in ListPageChild2"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :required="true" label="商户标志logo">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccessscbz"
                :clear="true"
                :url="form.mechanism_logo"
              ></avatar-uploader>
              <!-- 上传logo -->
            </el-form-item>
            <el-form-item :required="true" label="营业执照">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccesszmcl"
                :clear="true"
                :url="form.support_means"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item :required="true" label="商户资质">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccessjgzz"
                :clear="true"
                :url="form.qualifications_pic"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item :required="true" label="简介图片">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccessjjtp"
                :clear="true"
                :url="listPic[0]"
              ></avatar-uploader>
              <avatar-uploader
                @getImgUrl="handleAvatarSuccessjjtp1"
                :clear="true"
                :url="listPic[1]"
              ></avatar-uploader>
              <avatar-uploader
                @getImgUrl="handleAvatarSuccessjjtp2"
                :clear="true"
                :url="listPic[2]"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item :required="true" label="商户门面照">
              <avatar-uploader
                @getImgUrl="handleAvatarSuccess"
                :clear="true"
                :url="form.facade_view"
              ></avatar-uploader>
            </el-form-item>
          </div>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" class="butt" @click="add">确 定</el-button>
        </span>
      </el-dialog>


    <div>
      <div class="contentList">
        <h4>商户管理列表</h4>
        <el-table
          border
          ref="multipleTable"
          :header-cell-style="{ color: '#434343' }"
          :data="recording"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          :row-style="{heigth:'200px'}"
        >
          <el-table-column
            prop="id"
            align="center"
            label="id"
          ></el-table-column>
          <el-table-column
            prop="mechanism_name"
            align="center"
            label="商户名称"
          ></el-table-column>
          <el-table-column
            prop="mechanism_telephone"
            align="center"
            label="商户电话"
          ></el-table-column>
          <el-table-column
            prop="categories"
            align="center"
            label="商户类别"
          ></el-table-column>
          <!-- <el-table-column
            :formatter="fromTitle"
            prop="payee_logon_id"
            align="center"
            label="支付宝账号"
          ></el-table-column> -->
            <el-table-column
              prop="mechanism_addr"
              align="center"
              label="商户地址"
            ></el-table-column>
            <!-- <el-table-column
            :formatter="fromTitle"
            prop="contacts"
            align="center"
            label="负责人姓名"
          ></el-table-column>
          <el-table-column
            :formatter="fromTitle"
            prop="contact_information"
            align="center"
            label="负责人电话"
          ></el-table-column> -->
            <el-table-column
              prop="contact_telephone"
              align="center"
              label="主账号"
            ></el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              width="200"
              label="录入时间"
            ></el-table-column>
            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: #23c3c4"
                  >待审核</span
                >
                <span v-else-if="scope.row.status == 2" style="color: #b8b8b8"
                  >已通过</span
                >
                <span v-else-if="scope.row.status == 3" style="color: #ff6262"
                  >已拒绝</span
                >
                <span v-else-if="scope.row.status == 4" style="color: #ff6262"
                  >注销</span
                >
                <span v-else-if="scope.row.status == 5" style="color: #ff6262"
                  >取消</span
                >
                <span v-else style="color: #ff6262">冻结</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_recommend"
              align="center"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row)"
                  type="text"
                  size="small"
                  >查看详情</el-button
                >
                <el-button
                  @click="upstatus(scope.row)"
                  type="text"
                  size="small"
                  v-if="scope.row.status != 2"
                  >上架</el-button
                >
                <el-button
                  @click="upstatus(scope.row)"
                  type="text"
                  size="small"
                  v-if="scope.row.status == 2"
                  >下架</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!--分页-->
    <paging-fy  @currentPageChange="handleCurrentChange" 
      :currentPage="currentPage" :total="total"></paging-fy>

    <el-dialog
      class="dialog"
      title="地图"
      :visible.sync="dialogmap"
      :center="true"
    >
      <div>
        <mapdemo @getform="getmapdata"></mapdemo>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import mapdemo from "@/components/map.vue";
export default {
  name: "MerchantList",
  components: {
    mapdemo: mapdemo,
  },
  data() {
    return {
      Addmerchantlist: {
        name: "",
        address: "",
        personincharge: "",
        Telephone: "",
        remarks: "",
        longitude: "", //经度
        latitude: "", //玮度
      },
      Searchlist: {
        value1: "",
        value2: "",
        value3: "",
      },
      tableData: [
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普1518 弄",
        },
      ],
      activeName: 0,
      tabslist: ["未入驻", "已入驻"],
      nav: {
        firstNav: "商户管理",
        secondNav: "商户管理",
      },
      dialogmap: false,
      props: {
        label: "name",
        children: "zones",
      },
      region: [],
      listPic: [],
      ListPageChild: [],
      ListPageChild2: [],
      recording: [], //精品课程
      pageSize: 10,
      currentPage: 1,
      total: 0,
      NumData: {
        total: 0, //认证总数
        sportCount: 0,
        elseCount: 0,
        sportCount: 0,
        subjectsCount: 0,
        puzzleCount: 0,
      },
      adopt: 0,
      stay_num: 0,
      refuse_num: 0,
      dialogVisible: false,
      dialogVisible1: false,
      mechanism_name: "",
      mechanism_telephone: "",
      form: {
        type: "商户",
        mechanism_language: "中文",
        is_support_teach_paypal: true,
      },
      // 图片上传
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      userList: [],
      selected_user: "",
      curuser: {},
      mecstatus:2,
      Mectype:'商户'
    };
  },
  mounted() {
    this.GetList();
    this.gettoken();
    this.logbut();
    this.GetListPageChild();
    // this.regist()
  },
  methods: {

    //搜索点击
    Searchclick() {
      this.GetList()
    },
    //TAB 点击触发
    handleClicktab() {},
    //查询用户信息的mechanism_id，如果已绑定机构，则不让创建机构
    changeuser(e) {
      this.curuser = e;
    },
    getUser(query) {
      this.user_list = [];
      if (query && typeof query == "string") {
        let url = "/user/userInfo/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              mobile: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    logbut() {
      let url =
        "/user/mechanismCategory/queryListPageChild?&type=2&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild2 = res.data.data;
      });
    }, //获取父类目
    GetListPageChild() {
      let url =
        "/user/mechanismCategory/queryListPageChild?&type=1&status=2&source=商户";
      this.$axios.get(url).then((res) => {
        this.ListPageChild = res.data.data;
      });
    }, //子类目
    // 添加商户
    add() {
      if(this.curuser.merchant_id){
        if(this.curuser.merchant_id!=0 || this.curuser.mechanism_id!=0){
          this.$message({message:'该用户已存在绑定的机构'})
          return
        }  

      }

      this.form.restrictions_count =
       this.form.restrictions_count == "" ? 10 : this.form.restrictions_count;
      let url = "/user/mastermechanism/insertShop";
      let data = {
        ...this.form,
        // restrictions_count, //购买限制
        categories: this.region.toString(),
        introduction_pic: this.listPic.toString(),
        categories_child: this.form.categories_child.toString(),
        // user_id:  JSON.parse(localStorage.getItem('managerInfo')).user_id,
        user_id: this.curuser.user_id || 0,
        is_support_teach_paypal: true,
        status: 2,
        // type:'merchants',
        type: this.Mectype,
      };
      this.$axios.post(url, data).then((res) => {
        // alert(res.data.message);
        this.GetList();
        this.dialogVisible = !this.dialogVisible;
      });
    },
    onSubmit() {
      //查询
      if (this.mechanism_name == "" && this.mechanism_telephone == "") {
        this.GetList();
      } else {
        var url =
          "/user/mastermechanism/queryByMessage?type=商户&mechanism_name=" +
          this.mechanism_name +
          "&mechanism_telephone=" +
          this.mechanism_telephone;
        this.$axios.get(url).then((res) => {
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
        });
      }
    },
    getmapdata(data) {
      if (this.activeName == 0) {
        this.Addmerchantlist.address = data.address;
        this.Addmerchantlist.longitude = data.addrPoint.lng;
        this.Addmerchantlist.latitude = data.addrPoint.lat;
        
      } else {
        this.form.province = data.province;
        this.form.city = data.city;
        this.form.district = data.district;
        this.form.mechanism_addr = data.address;
        //经纬度
        this.form.longitude = data.addrPoint.lng;
        this.form.latitude = data.addrPoint.lat;
      }
      this.dialogmap = !this.dialogmap;
    },
    fromTitle(row, column, cellValue) {
      if (cellValue === "") {
        return "暂无";
      } else {
        return cellValue;
      }
    },
    handleClick(row) {
      this.$router.push({
        name: "MerchantDetail",
        query: { id: JSON.stringify(row) },
      });
    }, //详情
    upstatus(e) {
      let url = "/user/mastermechanism/update";
      let data = {
        id: e.id,
        status: e.status == 2 ? 3 : 2,
      };

      this.$axios.post(url, data).then((res) => {
        this.GetList();
      });
    }, // 推荐
    filterChange(filterObj) {
      // console.log(filterObj);
    },
    //列表
    GetList() {
      this.$axios({
        method: "get",
        url: "/user/mastermechanism/queryManagerListPage",
        params: {
          type: this.Mectype,
          pageSize: 10,
          currentPage: this.currentPage,
          status:this.mecstatus
        },
      })
        .then((res) => {
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
        })
        .catch((err) => {});
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
    },
    //图片
    // 获取token
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .$axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
        })
        .catch((err) => {});
    },
    handleCurrentChangeto(val) {
      this.$router.push({
        name: "Selectcourse",
        query: { id: JSON.stringify(val), events: this.$route.query.id },
      }); //拿到活动，选择商户，跳转+课程
    },
    handleAvatarSuccess(file) {
      this.form.facade_view = file;
    },
    handleAvatarSuccessjjtp(file) {
      this.listPic[0] = file;
    },
    handleAvatarSuccessjjtp1(file) {
      this.listPic[1] = file;
    },
    handleAvatarSuccessjjtp2(file) {
      this.listPic[2] = file;
    },
    handleAvatarSuccessscbz(file) {
      this.form.mechanism_logo = file;
    },
    handleAvatarSuccesszmcl(file) {
      this.form.support_means = file;
    },
    handleAvatarSuccessjgzz(file) {
      this.form.qualifications_pic = file;
    },
    beforeAvatarUpload(file) {
      this.gettoken();
    },
  },
};
</script>
<style scoped lang="less">
// @import '@/assets/css/Tutop.css';

.input-1 {
  width: 172px;
  border: 1px solid #dcdfe6;
  background-image: none;
  border-radius: 4px;
  padding: 0 15px;
  height: 40px;
  color: #606266;
}

*img {
  width: 100px;
}

/* 添加按钮 */
.blt {
  width: 100px;
  margin-right: 20px;
}

#dialogfrom > .el-form-item {
  width: 80%;
  display: flex;

  .el-form-item__content {
    width: 70%;
  }
}

#dialogfrom > .el-form-item__label {
  font-size: 12px;
  margin-top: 30px;
  width: 149px !important;
}

#dialogfrom > .el-input {
  margin-top: 30px;
  width: 100%;
}

.dialog {
  .dialogfrom {
    display: flex;
    justify-content: space-between;

    div {
      width: 100%;
      float: left;
      color: #333;
      font-size: 13px;
    }
  }

  .dialog-footer {
    width: 945px;
    height: 200px;

    .el-button {
      margin-right: 108px;
      width: 180px;
      height: 42px;
      background: #dedede;
      border-radius: 21px;
    }

    .butt {
      width: 180px;
      height: 42px;
      background: #ff7300;
      border-radius: 21px;
    }
  }
}

.el-dialog_header {
  width: 945px;
}

.el-dialog_body {
  width: 945px;
}

.contentList h4 {
  text-align: center;
  font-size: 30px;
}

.paging {
  position: relative;
  margin-top: 60px;
  margin-bottom: 50px;
}

.paging > .el-pagination {
  text-align: right;
}

.PERInformation {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #ffffff;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
